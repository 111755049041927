import React, {RefObject} from 'react';
import {Row, Col} from 'antd/lib/grid';
import {PercentageOutlined} from '@ant-design/icons';
import Input from 'antd/lib/input';
import {Form, Button, Radio, InputNumber, DatePicker, Select} from 'antd';
import Checkbox from 'antd/lib/checkbox';
import {FormInstance, RuleObject, RuleRender} from 'antd/lib/form';
import Text from 'antd/lib/typography/Text';
import {Store} from 'antd/lib/form/interface';

import moment from 'moment';

import CVATTooltip from 'components/common/cvat-tooltip';
import patterns from 'utils/validation-patterns';
import consts from "../../consts";
import AdvancedConfigurationForm from "../create-task-page/advanced-configuration-form";

export interface PatientAttributes {
    physician?: string;
    gender?: string;
    ethnicity?: string;
    processor?: string;
    endoscope?: string;
    age?: number;
    studydate?: object;
    bbps_ascending?: number;
    bbps_transverse?: number;
    bbps_descending?: number;
}

interface Props {
    onSubmit(values: PatientAttributes): void;

    initialValues: PatientAttributes;

    onReset(): void;
}

class PatientAttributesForm extends React.PureComponent<Props> {
    private formRef: RefObject<FormInstance>;

    public constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }

    public submit(): Promise<void> {
        const {onSubmit} = this.props;
        if (this.formRef.current) {
            return this.formRef.current.validateFields().then(
                (values: Store): Promise<void> => {
                    const entries = Object.entries(values);
                    console.log(values);
                    console.log(entries);
                    onSubmit(
                        ((Object.fromEntries(entries) as any) as PatientAttributes)
                    );
                    return Promise.resolve();
                },
            );
        }
        return Promise.reject(new Error('Form ref is empty'));
    }

    public resetFields(): void {
        console.log('resetFields');
        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }

    private getRadioOptions(opt_dict): any[] {
        const options = [];

        Object.keys(opt_dict).map(
            (key) => options.push(
                <Radio value={key}>{opt_dict[key]}</Radio>
            )
        );

        return (options);
    }

    private getSelectOptionsEndoscope(opt_dict): any[] {
        const options = [];

        let convertColor = (inp_str) => {
            var inp_arr = inp_str.split('c');
            if (inp_arr.length > 1) {
                return (<><span>{inp_arr[0]}</span><span style={{color: "red"}} >c</span><span>{inp_arr[1]}</span></>)
            } else {
                return inp_str;
            }
        };

        Object.keys(opt_dict).map(
            (key) => options.push(
                <Select.Option value={key}>{convertColor(opt_dict[key])}</Select.Option>
            )
        );
        return (options);
    }

    private getSelectOptions(opt_dict): any[] {
        const options = [];

        Object.keys(opt_dict).map(
            (key) => options.push(
                <Select.Option value={key}>{opt_dict[key]}</Select.Option>
            )
        );

        return (options);
    }

    private renderPhysician(): JSX.Element {
        return (
            <Form.Item
                help="Provide physician's name performing the study"
                label='Physician'
                name='physician'
                valuePropName='value'
            >
                <Input type='string'/>
            </Form.Item>
        );
    }

    private renderProcessor(): JSX.Element {

        const options = this.getSelectOptions(consts.DEFAULT_PROCESSOR_OPTIONS);

        const handleChange = e => {
            console.log('select processor', e);
            // setValuee(e.target.value);
            // onChange(e.target.value);
        };

        return (
            <Form.Item
                help='Provide processor type'
                label='Processor'
                name='processor'
                valuePropName='value'
            >
                <Select onChange={handleChange}>
                    {options}
                </Select>
            </Form.Item>
        );
    }

    private renderEndoscope(): JSX.Element {

        const options = this.getSelectOptionsEndoscope(consts.DEFAULT_ENDOSCOPE_OPTIONS);

        const handleChange = e => {
            console.log('select endoscope', e);
            // setValuee(e.target.value);
            // onChange(e.target.value);
        };

        return (
            <Form.Item
                help='Provide endoscope type'
                label='Endoscope'
                name='endoscope'
                valuePropName='value'
            >
                <Select onChange={handleChange}>
                    {options}
                </Select>
            </Form.Item>
        );
    }

    private renderAge(): JSX.Element {
        return (
            <Form.Item
                help="Specify patient's age"
                label='Age'
                name='age'
                valuePropName='value'
            >
                <InputNumber min={0} max={200}/>
            </Form.Item>
        );
    }

    private renderEthnicity(): JSX.Element {

        // retain possible options
        const options = this.getRadioOptions(consts.DEFAULT_ETHNICITY_OPTIONS);

        const handleChange = e => {
            console.log('radio checked ethnicity', e.target.value);
            // setValuee(e.target.value);
            // onChange(e.target.value);
        };

        return (
            <Form.Item
                help="Specify patient's ethnicity"
                name="ethnicity"
                label="Ethnicity"
            >
                <Radio.Group onChange={handleChange}>
                    {options}
                </Radio.Group>
            </Form.Item>
        );
    }

    private renderStudyDate(): JSX.Element {

        function onChange(date, dateString) {
            console.log("renderStudyDate");
            console.log(date, dateString);
        }

        return (
            <Form.Item
                help="Specify study date"
                name="studydate"
                label="Study date"
                valuePropName='value'
            >
                <DatePicker onChange={onChange}/>
            </Form.Item>
        );
    }

    private render_bbps_scores(segment: String): JSX.Element {
        const options = this.getSelectOptions(consts.DEFAULT_BBPS_OPTIONS);
        let help = `Specify BBPS in ${segment} colon`;
        let name = `bbps_${segment}`;
        let label = `BBPS in ${segment} colon`;

        return (
            <Form.Item
                help={help}
                name={name}
                label = {label}
                valuePropName="value"
            >
                <Select>
                    {options}
                </Select>
            </Form.Item>
        );
    }

    private renderGender(): JSX.Element {

        // retain possible options
        const options = this.getRadioOptions(consts.DEFAULT_GENDER_OPTIONS);

        const handleChange = e => {
            console.log('radio checked', e.target.value);
            // setValuee(e.target.value);
            // onChange(e.target.value);
        };

        return (
            <Form.Item
                help="Specify patient's gender"
                name="gender"
                label="Gender"
            >
                <Radio.Group onChange={handleChange}>
                    {options}
                </Radio.Group>
            </Form.Item>
        );
    }

    public render(): JSX.Element {
        const {initialValues, onSubmit, onReset} = this.props;

        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };

        return (
            <Form initialValues={initialValues} ref={this.formRef} layout='vertical'
                  onFinish={onSubmit} preserve={true}>
                <Row>
                    <Col>{this.renderPhysician()}</Col>
                </Row>
                <Row>
                    <Col>{this.renderStudyDate()}</Col>
                </Row>
                <Row>
                    <Col>{this.renderGender()}</Col>
                </Row>
                <Row>
                    <Col>{this.renderEthnicity()}</Col>
                </Row>
                <Row>
                    <Col>{this.renderAge()}</Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderProcessor()}
                    </Col>
                    &nbsp;
                    &nbsp;
                    <Col>
                        {this.renderEndoscope()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.render_bbps_scores("descending")}
                    </Col>
                    &nbsp;
                    &nbsp;
                    <Col>
                        {this.render_bbps_scores("transverse")}
                    </Col>
                    &nbsp;
                    &nbsp;
                    <Col>
                        {this.render_bbps_scores("ascending")}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update patient data
                            </Button>
                            &nbsp;
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>);
    }
}

export default PatientAttributesForm;
